.eventWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.eventDetails {
  flex: 1;
}

.headerSection {
  display: flex;
  padding-bottom: 14px;
}

.eventHeaderSection {
  padding-left: 12px;
  flex: 1;
}

.eventHeaderEdit {
  display: flex;
  align-items: center;
}

.eventHeadcount {
  line-height: 1;
  height: auto;
}

.eventSubtitle {
  color: rgba(56, 56, 56, 0.54);
  font-size: 13px;
  display: flex;
  line-height: 1;
}

.isHome {
  font-size: 14px;
}

.inlineTextBreak {
  margin: 0 3px;
}

.eventHeader {
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;
  margin: 0;
}

.eventContent {
  margin-top: 20px;
}

.eventInfoWrapper {
  max-width: 500px;
  width: 100%;
}

.eventInfo {
  padding: 12px;
}

.eventDirection {
  text-align: right;
  margin: 16px 0;
}

.eventAttendeeContainer {
  width: 100%;
  margin-top: 16px;
}

.eventAttendeeTabLabel {
  margin: 8px 16px 0px;
}

.toggleRSVP {
  margin: 12px 0 0 0;
}

.toggleLabelWrapper {
  display: flex;
}

.toggleLabelIcon {
  margin: 3px 5px 0 -5px;
  font-size: 18px !important;
}
