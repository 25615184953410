.eventPreview {
  margin-bottom: 20px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  color: inherit;
}

.eventPreview:hover .eventTime {
  opacity: 1;
}

.eventPreviewContent {
  display: flex;
  width: 100%;
  min-height: 70px;
  flex-wrap: wrap;
  padding: 16px;
  flex-direction: row;
}

.eventPreview:hover {
  box-shadow: rgba(252, 183, 183, 0.22) 0px 1px 6px,
    rgba(0, 0, 0, 0.38) 0px 1px 4px !important;
}

.eventTimeContainer {
  width: 110px;
  padding-right: 16px;
  margin-right: 24px;
  border-right: 1px solid rgba(56, 56, 56, 0.2);
}

.eventOpponentLocation {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 1%;
  flex-grow: 1;
  margin-right: 16px;
}

.eventRSVPs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

@media (max-width: 599.95px) {
  .eventRSVPs {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .eventPreview {
    margin: 0 12px 12px 12px;
  }
}
