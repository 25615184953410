.vsAt {
  opacity: 0.8;
  display: inline-flex;
  width: 16px;
  height: 19px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
}

.vs {
  font-size: 0.7rem;
}

.at {
  font-size: 0.8rem;
}

.opponentText {
  line-height: 36px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
