.card {
  margin: 0 20px;
}

.cardText {
  line-height: 1.6;
}

.formWrapper {
  display: flex;
}

.invitedWrapper {
  flex: 1;
  margin: 20px 0 0 40px;
}

@media (max-width: 992px) {
  .formWrapper {
    flex-direction: column;
  }

  .invitedWrapper {
    margin: 40px 0 40px 0;
  }
}
