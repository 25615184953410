.profilePic {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-size: cover;
}

.fieldsWrapper {
  width: 300px;
  margin-top: 24px;
}
