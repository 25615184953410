.location {
  line-height: 36px;
  display: flex;
  cursor: pointer;
}

.locationIcon {
  height: 18px !important;
  width: 18px !important;
  margin: 0 8px -3px 0px;
  opacity: 0.4;
}

.locationLink {
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
