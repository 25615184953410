.headcountTotal {
  font-size: 12px;
  font-weight: 700;
}

.genderCounts {
  opacity: 0.8;
  font-size: 12px;
  display: inline-block;
  margin-left: 5px;
}

.eventHeadcount {
  line-height: 36px;
  padding-right: 16px;
  height: 36px;
}

/* TODO: Move to material-ui breakpoint */
@media (max-width: 599.95px) {
  .genderCounts {
    display: none;
  }

  .eventHeadcount {
    width: 110px;
    min-width: 110px;
    padding-right: 16px;
    border-right: 1px solid rgba(56, 56, 56, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
  }

  .eventHeadcount:not(.eventSoon) {
    display: none;
  }
}
